.App {
  text-align: center;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.form input {
  width: 300px;
  height: 60px;
  margin: 10px;
}
